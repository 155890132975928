<template>
  <div class="page">
    <div class="content">
      <div class="title">发布科普</div>
      <input type="file" style="display: none" ref="file" @change="getFileData" />
      <div class="file-list">
        <div class="file-grid" v-for="(file, index) in fileList" :key="index">
          <div class="item">
            <div class="item-deshad" @click="deleteIMG(index)">
              <b-icon icon="trash"></b-icon>
            </div>
            <img :src="$host + file" />
          </div>
        </div>
        <div class="file-grid" v-show="fileList.length < 6">
          <div class="item up" @click="clickFile">
            <b-icon icon="image-fill"></b-icon>
            上传文件
          </div>
        </div>
      </div>
      <div class="form-title">
        <b-form-input
          placeholder="请输入标题"
          v-model="title"
          maxlength="32"
        ></b-form-input>
      </div>
      <b-form-textarea
        v-model="html"
        id="textarea"
        placeholder="输入科普内容"
        rows="3"
        max-rows="6"
        maxlength="600"
      ></b-form-textarea>
      <div class="tool">
        <div style="font-weight: bold; width: 100px">参与话题：</div>
        <b-button
          v-for="(item, index) in cates"
          :key="index"
          @click="active = item.id"
          :variant="active == item.id ? 'primary' : 'outline-primary'"
          class="checkbtn"
          >{{ item.title }}</b-button
        >
      </div>
      <div class="tool">
        <div style="font-weight: bold; width: 100px">关联项目：</div>
        <b-form-select v-model="selected">
          <b-form-select-option
            v-for="(item, index) in options"
            :key="index"
            :value="item.order_bn"
            >{{ item.product ? item.product.name : "" }}</b-form-select-option
          >
        </b-form-select>
      </div>
    </div>
    <div class="submit">
      <div class="submit-btn" @click="submit">确定发布</div>
    </div>
  </div>
</template>
<script>
import { uploader } from "../../common/ossUploadfile.js";
export default {
  data() {
    return {
      html: "",
      title: "",
      active: 0,
      options: [],
      selected: "",
      file1: null,
      fileList: [],
      cates: [],
    };
  },
  mounted() {
    this.getAnd();
    this.getCate();
  },
  methods: {
    getAnd() {
      this.$http.relatedtrial({}).then((res) => {
        let { data, code, msg } = res;
        if (code == 1) {
          console.log(data.items);
          this.options = data.items;
        } else {
          this.$store.commit("showToast", msg);
        }
      });
    },
    getCate() {
      this.$http.cate({}).then((res) => {
        let { data, code, msg } = res;
        if (code == 1) {
          this.cates = data;
        } else {
          this.$store.commit("showToast", msg);
        }
      });
    },
    submit() {
      let data = {
        type: 0,
        title: this.title,
        content: this.html,
        resource: this.fileList.join(),
        science_cate_id: this.active,
        order_bn: this.selected,
      };
      if (data.title == "") return this.$store.commit("showToast", "请输入标题");
      if (data.content == "") return this.$store.commit("showToast", "请输入文章内容");
      if (data.resource == "") return this.$store.commit("showToast", "请上传图片或视频");
      if (data.science_cate_id == "")
        return this.$store.commit("showToast", "请选择标签");
      // if(data.order_bn == '') return this.$store.commit("showToast", '请选择关联项目')
      console.log(data);
      this.$http.addcontent(data).then((res) => {
        if (res.code == 1) {
          this.$store.commit("showToast", "发布成功");
          setTimeout(() => {
            this.$router.back();
          }, 2000);
        } else {
          this.$store.commit("showToast", res.msg);
        }
      });
    },
    formatNames(files) {
      console.log(files);
      return files.length === 1 ? files[0].name : `${files.length} files selected`;
    },
    getFileData() {
      const inputFile = this.$refs.file.files[0];
      uploader(inputFile).then((res) => {
          this.fileList.push(res.url);
        })
        .catch(() => {
          this.$store.commit("showToast", "上传失败，请重试！");
        });
    },
    clickFile() {
      if (this.fileList.length > 5) {
        return this.$store.commit("showToast", "最多上传6张图片");
      }
      this.$refs.file.dispatchEvent(new MouseEvent("click"));
    },
    deleteIMG(i) {
      console.log(i);
      this.fileList.splice(i, 1);
    },
  },
};
</script>
<style scoped lang="scss">
.page {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.content {
  width: 1200px;
  margin: 20px 0;
}
.title {
  height: 40px;
  line-height: 40px;
  // border-bottom: 2px solid #fd9852;
  font-size: 20px;
  font-weight: bold;
  width: 100px;
}
.file-list {
  display: flex;
  margin: 10px 0;
  flex-wrap: wrap;
  border: 1px solid #ccc;
  padding: 10px 0;
  .item {
    width: 250px;
    height: 250px;
    border: 1px #ccc solid;
    img {
      width: 248px;
      height: 248px;
      object-fit: cover;
    }
    .item-deshad {
      width: 40px;
      height: 40px;
      position: absolute;
      color: #fff;
      margin-left: 208px;
      line-height: 40px;
      font-size: 20px;
      text-align: center;
      background: #999;
      opacity: 0.8;
    }
  }
  .file-grid {
    width: 299.5px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px 0;
  }
  .item:hover {
    cursor: pointer;
  }
  .up {
    text-align: center;
    line-height: 250px;
    color: #999;
  }
}
.form-title {
  margin-top: 20px;
}
#textarea {
  width: 1200px;
  // height: 800px;
  margin: 10px 0;
}
.tool {
  // margin: 10px 0;
  display: flex;
  height: 48px;
  align-items: center;
}
.checkbtn {
  height: 32px;
  line-height: 32px;
  margin-right: 20px;
  padding: 0 20px;
}
.custom-select {
  height: 32px;
  min-width: 360px;
  option {
    height: 32px;
    line-height: 32px;
  }
}
.submit {
  display: flex;
  margin-top: 20px;
  margin-bottom: 50px;
  justify-content: center;
  width: 1200px;
}
.submit-btn {
  width: 160px;
  height: 40px;
  line-height: 40px;
  background: linear-gradient(129deg, #fbc2ae 0%, #fe6039 100%);
  text-align: center;
  color: #fff;
  opacity: 0.9;
  border-radius: 20px;
}
.submit-btn:hover {
  cursor: pointer;
}
.custom-file-input {
  display: none;
}
</style>
